footer{
    background-color: white;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size:0.8rem;
    font-weight: '300';
    text-align: center;
    button{
        font-weight: '300';
        background: none;
        border: none;
    }
    padding-bottom: 2rem;
    img{
        height: 35px;
        width: auto;
    }
    .border-right{
        border-right:solid 1px
    }
}