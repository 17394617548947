.form-check{
    
    line-height: 1.6em;
    height: 1.6em;

    label{
        color: black;
        font-weight: 500;
    }
}
.form{
    font-weight: bold;
    font-size: 1rem;
    color: #b2202e;
    width: 100%;
    
}
.action-button {
    background-color: #b2202e !important;
    border-radius: 20px !important;
    color: white;
    font-weight: bold;
    white-space: nowrap;
    border: none;
    font-size: 1rem;
    padding: 8px 25px;
  }
