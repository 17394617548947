@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  overflow-x: hidden;
}

body {
  line-height: 1.2 !important;
  margin: 0;
  font-family: "Montserrat", sans-serif !important;//sooonedit
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@media (max-width: 400px) {
  h1 {
    font-size: 1rem !important;
  }
}

@media (max-width: 920px) {
  p {
    text-align: justify;
  }
}

@media (max-width: 750px) {
  h1 {
    font-size: 1.5rem !important;
  }
}

.dropdown-menu.show {
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
x .red-bookmark-container {
  width: 100%;
  height: 39px;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  padding-top: 90px;
  // background-color: #c9c2c0;
}

.carousel-caption {
  position: absolute;
  top: 50%;
}

.carousel {
  background-color: white;
}
.carousel-indicators [data-bs-target] {
  width: 5px !important;
}

.carousel-item {
  min-height: 400px;
  h1{
    font-size: 3rem;
    font-weight: bold;
  }
  img {
    // min-height: 300px;
  }
}

.landing {
  background-image: url("img/Home/home-background.png");
  min-height: 870px;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  .main-logo {
    width: 45%;
    height: 200px;
    display: flex;
    min-width: 260px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-top: 25%;
    #normal-logo {
      width: 100%;
    }

    #chinese-logo {
      width: 33%;
    }

    .zantat-group {
      padding-top: 16px;
      color: #155573;
      font-weight: 500;
      @media (max-width: 400px) {
        font-size: 0.7rem;
      }

      span {
        font-weight: 800;
      }
    }
  }

  @media (max-width: 1300px) {
    background-size: cover;
  }
  @media (max-width: 900px) {
    background-position: 90% 0;
  }
}

@media (min-width: 576px){
  .modal-dialog {
     width: 75% !important;
     max-width: 850px !important;
  }
}

.our-company {
  background-color: #dcdddf;
  padding-top: 32vh;
  text-align: center;
  background-image: url("img/our-company/background.jpg");
  min-height: 1050px;
  background-repeat: no-repeat;
  background-size: cover ;
  background-position: bottom;

  @media (max-width: 920px) {
    padding-top: 2.5rem;
  }
  h2 {
    font-size: 1.6rem;
    font-weight: 400;
  }
  h1 {
    font-size: 1.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    .quotation-mark {
      width: 100%;
      img {
        width: 5.5rem;
        height: auto;
      }

      @media (max-width: 920px) {
        img{
          width: 3.2rem;
          height: auto;
        }
      }
      
    }
  }

  .introduction {
    text-align: left;
    display: flex;
    align-items: flex-start;
    padding: 0px 2rem;
    
    @media (max-width: 920px) {
      display: block;
      p{
        color:black;
        font-weight: 400 !important;
      }
    }

    div {
      flex: 1;
      margin-right: 15px;
    }
  }
}

.company-mission {
  display: flex;
  // padding-top: 10%;
  justify-content: space-around;
  background-color: #fcfeff;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 5rem;
  padding-right: 2rem;
  @media (max-width: 800px) {
    flex-direction: column;
    padding-left: 5px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    padding-left: 2rem;
    .title-color {
      h2 {
        font-size: 2.5rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 50%;
    width: 500px;
    height: 500px;
    // background-color: white;
    position: relative;


    @media (max-width: 1300px) {
      width: 400px;
      height: 400px;
    }

    @media (max-width: 1000px) {
      width: 250px;
      height: 250px;
    }

    @media (max-width: 920px) {
     min-height: 300px;
     h2{
      text-align: center !important;
      padding-left: 0 !important;
     }
    }

    @media (max-width: 800px) {
      margin-bottom: 2rem;
    }


    @media (max-width:400px){
      padding-top: 2rem;
      padding-bottom: 5rem;
    }

    img {
      width: 550px;
      height: 550px;
      border-radius: 50%;
      @media (max-width: 1300px) {
        width: 500px;
        height: 500px;
      }

      @media (max-width:1250px){
        width:400px;
        height:400px;
      }

      @media (max-width: 1000px) {
        width: 300px;
        height: 300px;
      }
    }
    h2 {
      top: 20%;
      right: 10%;
      color: white;
      font-size: 4rem;
      font-weight: 100;
      @media (max-width: 1300px) {
        font-size: 3rem;
      }

      @media (max-width: 1000px) {
        font-size: 2rem;
      }
    }
  }
  .description {
    flex: 1.5;
    font-weight: 400;
    // margin-left: 3rem;
    h2 {
      font-size: 2rem;
      font-weight: 400;
      span {
        font-size: 3rem;
        font-weight: 700;
      }
    }
    @media (max-width: 920px) {
      h2{
        text-align: center;
        font-size: 1.5rem !important;
        span{
          font-size: 1.5rem !important;

        }
      }
      p{
       font-size: 1rem;
      }
     }
  }
}

.company-mission-1{
  .circle{
    flex:1;
    align-items: center;
    justify-content: center;
    h2{
      padding-left: 2rem;
    }
  }
  .description{
    flex:1;
  }
}
.our-product-background {
  background-image: url("img/our-products/background.jpg");
  // min-height: 1050px;//sooonedit
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;
  padding-bottom: 2rem;
  

  .circle{
    flex:1;
    align-items: center;
    justify-content: center;
    h2{
      padding-left: 2rem;
    }
  }

  

  div{
    flex:1;
  }

  @media (max-width: 800px) {
    min-height: 800px;
    
  }

  @media (max-width: 800px) {
    .common-desc {
      text-align: justify;
    }
    .sub-desc{
      text-align: center;
      padding-bottom: 1rem;
    }

    .title-color{
      display: flex;
      flex-direction: column;
      align-items: center;
      .action-button{
        width: fit-content;
        margin-top: 1rem;
      }
    }

    .circle::after{
      content: 'OUR PRODUCTS';
      text-align: center;
      color: white;
      position: absolute;
      font-size: 2.5rem;
      font-weight: 300;
    }

  }
  h2 {
    font-size: 4rem;
    font-weight: 200;
  }
  //sooonedit
  // div {
  //   margin: 0px 20px;
  // }

  .sub-desc {
    font-size: 1.5rem;
  }

  .common-desc {
    font-size: 1.1rem;
  }
}

.no-margin {
  margin: 0;
}



.our-company > div {
  display: flex;
  justify-content: space-around;
}

.red-bookmark {
  width: 90px;
  height: 19px;
  background-color: #b2202e !important;
  transition: margin-left 1s;
}

.carousel-indicators [data-bs-target] {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}

.carousel-caption {
  padding-bottom: 10% !important;
}

.caption-header {
  top: 0;
  opacity: 0.6;
  h3 {
    font-weight: 300 !important;
    font-size: 1.5rem;
  }
}

.caption-content {
  font-size: 2.5rem;
}

header {
  background-color: white;
  box-shadow: 0px 2px 5px grey;
  padding: 0px 5px;
  min-height: 90px;
  position: fixed;
  width: 100%;
  z-index: 99;
}

nav {
  margin: 0 16px;
  display: flex;
  // grid-template-columns: auto auto auto auto auto auto;
  align-items: center;
  justify-content:space-between;
  height: 90px;
  padding-bottom: 16px;
  z-index: 50;



  @media (min-width:920px) {
    .nav-item{
      flex:1 0 auto;
    }
    .nav-item:first-child{
      margin-left: 2rem;
    }
  
    .nav-item:last-child{
      margin-right: 2rem;
    }
  
    
  }

 
 


  #basic-nav-dropdown{
    color:white;
    position: relative;
  }

  #basic-nav-dropdown::after{
    content:'';
    background-image: url(./img/icon/hamburger.svg);
    background-size: contain;
    background-repeat: no-repeat;
    top:-20;
    right: 0;
    left: 0;
    bottom: 0;
    width: 35px;
    height: 35px;
    position: absolute;
    z-index: 2;
  }
  
  div {
    display: flex;
    -webkit-justify-content: flex-end;
    align-items: flex-end;
    -webkit-align-items: flex-end;    
    justify-content: center;
    position: relative;
    height: 100%;
    // padding-bottom: 12px;
    cursor: pointer;
    span {
      text-decoration: none;
      color: #155573;
      line-height: 0.75rem;
    }
  
  }

  .selected-origin {
    span{
      color:#bf1e2d !important;

    }
  }

  .selected:before {
    content: "";
    border-top: solid 12px #bf1e2d;
    position: absolute;
    top: 0;
    width: 100%;
  }

  
}

nav.small-nav{
  display: flex;
  justify-content: space-between;
  width: 100%;
  -webkit-justify-content: space-between;

}

.header-logo-container {
  img {
    height: 30px;
    width: auto;
  }
}

a {
  text-decoration: none !important;
}

a:hover {
  color: white !important;
}

// .mt2r {
//   margin-top: 2rem;
// }

//sooonedit
.circle-outer-container {
  position: relative;
  width: 100%;
  margin: auto;
}

.circle-container {
  position: absolute;
  bottom: calc(-1*(100vh / 8));//sooonedit
  display: flex;
  height: calc(100vh / 4);
  width: 100%;
  justify-content: space-around;
  a {
    color: white;
    font-weight: 900;
    div {
      background-size: cover;
      background-position: 0 0;
      border-radius: 50%;
      width: 280px;//sooonedit
      overflow: hidden;
      height: 280px;//sooonedit
      background-color: white;
      border: solid 2px white;
      box-shadow: 5px 5px 4px grey;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      img{
        position: absolute;
        filter: opacity(0.9);
        background-size: cover;
        border-radius: 50%;
        opacity: 0.9;
        box-sizing: border-box;
        transform: scale(1.03);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      
      &:hover {
        img{
          transform: scale(1.03);
          filter: opacity(1);

        }
      }
      span {
        z-index: 1;
      }
      @media (max-width: 1300px) {
        width: 200px;
        height: 200px;
      }

      @media (max-width: 900px) {
        width: 150px;
        height: 150px;
      }

      @media (max-width: 700px) {
        width: 100px;
        height: 100px;
      }
    }

  }
}

.contact {
  display: flex;
  justify-content: space-around;
  padding: 20px 20px;
  padding-top: 6rem;
  background-color: white;
  padding-bottom: 5rem;
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  div:nth-child(1){
    flex:1;
  }
  div:nth-child(2){
    flex:2;
  }
  div:nth-child(3){
    flex:1;
  }
  div {
    margin-right: 1rem;
    @media (max-width: 920px) {
      margin-right: 0rem;
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 920px) {
    flex-direction: column;
    text-align: center;
  }
}

.contact-us{
  display: flex;
  padding: 20px 20px;
  padding-top: 6rem;
  background-color: white;
  padding-bottom: 5rem;
  
  button{
    background-color: #b2202e !important;
    border-color: #b2202e !important;
  }
}
.mapouter {
  position: relative;
  text-align: right;
  height: 230px;
  width: inherit;
}

.contact-details {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: auto auto auto auto;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: inherit;
}

.action-button {
  background-color: #b2202e !important;
  border-radius: 20px !important;
  color: white;
  font-weight: bold;
  white-space: nowrap;
  border: none;
  font-size: 1rem;
  padding: 8px 25px;
}

.applications {
  background-color: #d1d2d4;
  padding: 0rem 15px;
  padding-bottom: 1.5rem;
  @media (max-width: 400px) {
   
      padding: 0px; 
  }
  
  @media (max-width: 920px) {
    .carousel{
      padding-top: 0;
    }
      padding: 0px; 
  }
}



//color

.title-color {
  color: #b2202e !important;
}

.blue-color {
  color: #13536d;
}

.black-color {
  color: black !important;
}

.light-blue-color {
  color: #035373;
}

.white-color {
  color: white;
}

.font-bold {
  font-weight: 700 !important;
}

.font-ultra-bold {
  font-weight: 900 !important;
}

.dropdown-item:active {
  color: white !important;
  background-color: #155573 !important;
}

//accordian
.accordion {
  background-color: #c9c2c0;
  font-size: 1.2rem;
  .accordion-body {
    background-color: #d01d32;
    color: white;
    display: flex;
    justify-content: space-between;
    img {
      margin-left: 10px;
    }
  }

  .accordion-button {
    background-color: #d01d32;
    color: white;
    border-bottom: none;
    font-weight: bold;
    font-size: 1.5rem;
  }
  .accordion-button::after {
    background-image: url(img/icon/plus.png);
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url(img/icon/close.png);
  }

  .accordion-button:not(.collapsed) {
    background-color: #d01d32;
    color: white;
  }

  .accordion-button:focus {
    box-shadow: none;
  }
  .accordion-item {
    border: none;
    margin-bottom: 5px;
  }
}
