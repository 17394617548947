.center{
  text-align: center;
}
.application-carousel{
  background: #d01d32;
  color: white;

  @media(max-width: 400px){
    height: 550px;
  }
  @media(max-width: 920px){
    height: 600px !important;
  }

  .caption-image-cover{
    display:flex;
    justify-content: center;
    align-items: center;
    min-height:300px;
    @media(max-width:400px){
      min-height:200px
    }
  }
}
.caption-img{
  margin: 0.5rem;
  width:90%;
  max-width:400px;
}

.caption-img-2{
  margin: 0.5rem;
  width:45%;
  max-width:400px;
}
.caption-title{
  display:flex;
  span{
    font-size: 2em; 
    font-weight: 600;
    flex: auto; 
    padding-top: 2rem;
  }
}
.caption-content{
  text-align: justify;
  font-size: 1em;
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
  span{
    max-width: 600px;
  }
}

.carousel-caption{
  padding: 1.25rem 1.25rem !important;
  left:0;
  right:0;
}
.factory-and-warehouse {
    // min-height: 600px;
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    // background-position: 0 0;
    position:relative;    
    // justify-content: flex-end;
    // align-items: center;
    // padding-right: 10%;
    color: white;
  
    .carousel{
      padding-top:0% !important;
    }
// background-color: #FFF;
//   width: 350px;
//   height: 350px;
//   position: absolute;
//   opacity: 0.8;
//   border-radius: 50%;
//   background-image: url('http://via.placeholder.com/50x50');
//   background-size: 350px;
//   background-repeat: no-repeat;
//   background-position: 50%;
  
    // top: 0;
    // left: calc(50% - 175px);
    // background: #444;

    @media (max-width:800px) {
      p{
        text-align: justify;
      }
    }
    .circle-container-1 {   
    
      border-radius: 50%;
      width: 600px;
      height: 600px;
      left: 50%;
      top:0;
      bottom:0;
      margin-top: auto;
      margin-bottom: auto;
      z-index: 2;
      justify-content: center;
      align-items: center;
      display: flex;
      position: absolute;
  
      @media(max-width:1250px){
        width: 500px;
        height:500px;
      }
      @media(max-width:1050px){
        width: 450px;
        height:450px;
      }
      @media (max-width: 920px) {
        width: 400px;
        height: 400px;
        left: calc(50% - 200px);
        margin:0;
        position: relative;
        margin-bottom: 2rem;
        h1{
          font-size: 2.5rem !important;
        }
        background-image: url("../img/factory/factory1.jpg");
        background-size: cover;
        filter: contrast(1.3);

        &::before {
          background-image: url("../img/factory/gradient.jpg") !important;
          filter: contrast(1.1);
          opacity: 0.4;
        } 
      }
  
      @media (max-width: 480px) {
         h1{
          font-size: 2rem !important;
        }
        width: 300px;
        height: 300px;
        left: calc(50% - 150px);
      }
  
      &::before {
        content: "";
        background-image: url("../img/factory/gradient.jpg");
        filter: contrast(1.1);
  
        background-size: contain;
        border-radius: 50%;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
      }
  
      .circle {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 0;
  
        .text {
          z-index: 3;
          margin: 20px;
          text-align:center;
          text-align:-webkit-center;
          
          h1,
          p {
            width: 95%;
            text-align: center;
          }
        }
      }
    }
  @media (max-width: 920px) {
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    background-image: url("../img/our-company/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
    p {
      // padding-top: calc(2rem + 500px);
      color: black;
    }
  }
    
    
  }
